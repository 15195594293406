import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import RenderMarkdown from "../cms/renderMarkdown"
import SEO from "../components/SEO"

const MyApproach = ({ data: { heroImage, content } }) => {
  return (
    <Layout>
      <SEO title="Counseling in Birmingham, MI | Sydney Reiter | My Approach to Counseling" />
      <MainHero
        heroImage={content.frontmatter.hero_image}
        title={content.frontmatter.title}
        subTitle={content.frontmatter.subTitle}
        height="is-medium"
        crop="20"
      />
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            <RenderMarkdown md={content.body} />
          </div>
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default MyApproach

export const query = graphql`
  {
    content: mdx(frontmatter: { section: { eq: "myApproach" } }) {
      id
      frontmatter {
        title
        subTitle
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      body
    }
  }
`
